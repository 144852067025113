// import { mockTopCollections } from 'pages/homepage/mockData';
import { useEffect, useState } from 'react';
import { customAxios, apiAuthAxios } from 'services/customAxios';
// import moment from 'moment';
import { getPriceRecentlyListedItems } from 'utils/getPriceRecentlyListedItems';
// import mockAvt from '../../assets/img/home_08_avt.png';
import makeBlockie from 'ethereum-blockies-base64';
import useScreenSize from 'components/common/useScreenSize';
import { ReactComponent as IconMoreBoard } from '../../assets/icon/double_down_arrow.svg';
import { HandleMediaError } from 'components/common/HandleMediaError';

type TopNfts = {
  seeMore: boolean;
};
const TopNfts: React.FC<TopNfts> = ({ seeMore }) => {
  // const [tabIsSelected, setTabIsSelected] = useState('tab03');
  const [topNfts, setTopNfts] = useState([]);
  const [seeMoreMobile, setSeeMoreMobile] = useState(false);
  // const today = moment().format('YYYY-MM-DD');
  // const dayAgo = moment().subtract(1, 'days').format('YYYY-MM-DD');
  // const weekAgo = moment().subtract(1, 'weeks').format('YYYY-MM-DD');
  // const monthAgo = moment().subtract(1, 'months').format('YYYY-MM-DD');
  // const [startDate, setStartDate] = useState(monthAgo);
  // const handleTabSelect = (tab) => {
  //   if (tab === 'tab01') setStartDate(dayAgo);
  //   else if (tab === 'tab02') setStartDate(weekAgo);
  //   else if (tab === 'tab03') setStartDate(monthAgo);
  //   setTabIsSelected(tab);
  // };
  const getTopNfts = async () => {
    try {
      const response = await customAxios.get(
        `/api/service/collections?limit=${screenSize > 768 ? (seeMore ? 10 : 5) : seeMoreMobile ? 10 : 5}`
      );
      if (response.data?.data?.sellbooks) setTopNfts(response.data?.data?.sellbooks);
    } catch (error: any) {
      console.log(new Error(error));
    }
  };
  useEffect(() => {
    getTopNfts();
  }, [seeMore, seeMoreMobile]);
  const screenSize = useScreenSize();

  return (
    <div className="wrapper-top-board">
      <div className="d-flex flex-column justify-content-start gap-32px gap-xs-16px w-100">
        <div className="flex-between">
          <div className="fw-9 fs-28 fs-xs-18 lh-33 lh-xs-21">Top NFT Listings</div>
          {/* <div className="tabs-top-board">
          <div className="item-tab-board">
            <div
              className={`tab ${tabIsSelected === 'tab01' && 'tab-selected'} `}
              onClick={() => handleTabSelect('tab01')}
            >
              1D
            </div>
          </div>
          <div className="item-tab-board">
            <div
              className={`tab ${tabIsSelected === 'tab02' && 'tab-selected'} `}
              onClick={() => handleTabSelect('tab02')}
            >
              7D
            </div>
          </div>
          <div className="item-tab-board">
            <div
              className={`tab ${tabIsSelected === 'tab03' && 'tab-selected'} `}
              onClick={() => handleTabSelect('tab03')}
            >
              1M
            </div>
          </div>
        </div> */}
        </div>
        <table className="top-board">
          <thead>
            <tr className="fs-14 fs-xs-10 fw-7 color-8787d6">
              <th className="text-center w-56px w-xs-42px min-w-xs-42px">Rank</th>
              <th className="w-278px w-xs-128px">NFT</th>
              <th className="w-136px w-xs-75px  min-w-xs-75px text-start">User</th>
              <th className="w-100px w-xs-90px min-w-xs-90px">OpenSea FP</th>
              {/* <th>User</th> */}
            </tr>
          </thead>
          <tbody className="cjk-font">
            {topNfts.map((item, index) => (
              <tr key={index}>
                <td className="text-center">{index + 1}</td>
                <td className="max-w-xs-128px">
                  <div className="flex-start gap-15px gap-xs-8px">
                    <div className="w-40px w-xs-26px h-40px h-xs-26px">
                      {item.nftInfo?.image?.split('.')?.pop() === 'mp4' ||
                      item.nftInfo?.extension === 'mp4' ||
                      item.nftInfo?.metadata?.image?.split('.')?.pop() === 'mp4' ||
                      item.nftInfo?.metadata?.extension === 'mp4' ||
                      item.nftInfo?.animation_url ||
                      item.nftInfo?.metadata?.animation_url ? (
                        <video
                          muted
                          playsInline
                          webkit-playsinline="true"
                          controlsList="nodownload"
                          className="w-100 border-radius-4 h-100 object-fit-cover"
                          style={{ objectFit: 'cover' }}
                          onError={HandleMediaError}
                          key={`${(
                            item.nftInfo?.alt_url ||
                            item.nftInfo?.image ||
                            item.nftInfo?.metadata?.alt_url ||
                            item.nftInfo?.metadata?.image
                          )
                            ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                            .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')}#t=0.001`}
                        >
                          <source
                            src={`${(
                              item.nftInfo?.alt_url ||
                              item.nftInfo?.image ||
                              item.nftInfo?.metadata?.alt_url ||
                              item.nftInfo?.metadata?.image
                            )
                              ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                              .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')}#t=0.001`}
                            type="video/mp4"
                          />
                        </video>
                      ) : (
                        <img
                          src={
                            item.nftInfo?.alt_url ||
                            item.nftInfo?.image ||
                            item.nftInfo?.metadata?.alt_url ||
                            item.nftInfo?.metadata?.image
                              ?.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
                              .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')
                          }
                          className="w-100 border-radius-4 h-100 object-fit-cover"
                          alt=""
                          onError={HandleMediaError}
                        />
                      )}
                    </div>
                    <div className="line-clamp-2 w-168px w-xs-112px fw-5">{item.nftInfo?.name}</div>
                  </div>
                </td>
                <td className="text-start">
                  <div className="flex-start gap-6px">
                    {screenSize > 768 && (
                      <div className="w-26px w-xs-20px min-w-26px min-w-xs-24px h-26px h-xs-20px">
                        <img
                          src={item.userProfileImage || makeBlockie(item.userAddress.toLowerCase())}
                          alt="avatar"
                          className="w-100 rounded-circle h-100"
                        />
                      </div>
                    )}
                    <div className="line-clamp-1 color-777 fw-5">{item.userName}</div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-end flex-column gap-4px">
                    <div className="flex-start gap-4px">
                      <div className="line-clamp-1">{getPriceRecentlyListedItems(item.collections?.floorPrice)}</div>
                      <span className="text-uppercase">{item.collections?.floorPriceQuote}</span>
                    </div>
                    <div className="color-777 fw-4 lh-17 lh-xs-14">
                      (${getPriceRecentlyListedItems(item.collections?.floorPriceUsd)})
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {screenSize < 769 &&
        (seeMoreMobile ? (
          <div className="wrapper-hide-mb" onClick={() => setSeeMoreMobile(false)}>
            <IconMoreBoard className="icon-hide-board" width={10} height={10} />
            <span>Hide</span>
          </div>
        ) : (
          <div className="wrapper-more-mb" onClick={() => setSeeMoreMobile(true)}>
            <div className="flex-center gap-8px">
              <IconMoreBoard width={10} height={10} />
              <span>More</span>
            </div>
          </div>
        ))}
    </div>
  );
};
export default TopNfts;
