// @ts-nocheck
import axios from 'axios';
import { AbcLoginResponse } from '../abc/schema/account';
// const mcache = require('memory-cache');
import mcache from 'memory-cache';
import { ethers } from 'ethers';
import { getSession, abcLogin, abcRefresh, abcJoin, getUser, updateAbcAddress } from '../services/services';
import Web3Modal from '@colligence/web3modal';
import { AbcWeb3Provider } from 'libs/abc-web3-provider/src/index';
// import { AbcWeb3Provider } from '@colligence/abc-web3-provider';
import { RPC_URLS } from '../config';
import env from '../env';
import secureLocalStorage from 'react-secure-storage';
import { accountRestApi, controllers } from '../abc/background/init';
const { accountController, abcController } = controllers;

type License = {
  check1: boolean;
  check2: boolean;
  check3: boolean;
  check4: boolean;
  check5: boolean;
};

const delay = (time = 1000) => new Promise((resolve) => setTimeout(resolve, time));

const HelperUtil = {
  refreshAbcAuth: async () => {
    try {
      const cachedAccessToken = mcache.get('refreshAbcAuthAccessToken');
      if (cachedAccessToken) return cachedAccessToken;
      const abcAuth = JSON.parse(window.localStorage.getItem('abcAuth'));
      // const abcAuth = JSON.parse(<string>secureLocalStorage.getItem('abcAuth'));
      const preRefreshToken = abcAuth.refreshToken;
      // const res = await axios.post(`${process.env.ABC_SERVER_ADDRESS}/auth/auth-service/refresh`, queryString.stringify({ grant_type: 'refresh_token', refresh_token: preRefreshToken }));
      const res = await abcRefresh({ refresh_token: preRefreshToken }).catch((err) =>
        console.log(`abcRefreshErr1:::${err.message}`)
      );
      if (!res?.data?.access_token) {
        console.log(`refreshAbcAuthError:::`, res);
        window.localStorage.removeItem('abcAuth');
        alert('refresh AbcAuth Error. Please log in again.(3)');
        window.location.href = `${process.env.REACT_APP_API_SERVER}/auth/logout`;
        return null;
      } else {
        const data = {
          accessToken: res.data.access_token,
          refreshToken: res.data.refresh_token,
          tokenType: res.data.token_type,
          expiresIn: res.data.expire_in,
        };
        // console.log('===== refresh token ===>', abcAuth);
        window.localStorage.setItem('abcAuth', JSON.stringify(data));
        mcache.put('refreshAbcAuthAccessToken', res.data.access_token, 10000);
        return res.data.access_token;
      }
    } catch (error) {
      console.log(`refreshAbcAuthErrorCatch:::`, error.message);
      window.localStorage.removeItem('abcAuth');
      alert('refresh AbcAuth Error. Please log in again.(2)');
      window.location.href = `${process.env.REACT_APP_API_SERVER}/auth/logout`;
      return null;
    }
  },
  abcLogin: async () => {
    const getSessionRes = await getSession().catch((error) => {
      return console.log(`getSessionErrorCatch:::`, error.message);
    });
    // console.log('getSessionRes:::', getSessionRes);
    // alert(JSON.stringify(getSessionRes.data.providerAuthInfo))

    // 구버전은 getSession에 provider_token가 없으므로 강제 로그아웃
    // const isNewApp = window.webkit?.messageHandlers?.activeABCWallet || window.Android?.activeABCWallet;
    // const userProvider = getSessionRes?.data?.user?.provider;
    // const authInfoProvider = getSessionRes?.data?.providerAuthInfo?.provider;
    const authInfoProviderToken = getSessionRes?.data?.providerAuthInfo?.provider_token;
    // const isLoginWithoutProviderToken = authInfoProvider && !authInfoProviderToken;
    // if (isNewApp && isLoginWithoutProviderToken)
    //   return (window.location.href = `${process.env.REACT_APP_API_SERVER}/auth/logout`);
    // const isTalkenApp = window.navigator.appName === "Talken";
    // console.log("sat?:::",!isTalkenApp, userProvider, !authInfoProviderToken)

    // TODO: check login issue
    // if (userProvider && !authInfoProviderToken) return (window.location.href = `${process.env.REACT_APP_API_SERVER}/auth/logout`);

    if (!authInfoProviderToken) return console.log('abcSnsLogin error No provider_token');
    const data = {
      token: getSessionRes.data.providerAuthInfo.provider_token,
      service: getSessionRes.data.providerAuthInfo.provider,
      audience: 'https://mw.myabcwallet.com',
    };
    console.log('beforeabcLoginRes:::');
    const abcLoginRes = await abcLogin(data).catch((err) => console.log(`abcLoginResErr1:::${err.message}`));
    console.log('abcLoginRes777:::', abcLoginRes);
    if (abcLoginRes?.data?.error === 'invalid_token') {
      // sns token 만료의 경우도 abcLoginResErr:::invalid_token
      window.localStorage.removeItem('abcAuth');
      alert('refresh AbcAuth Error. Please log in again.(1)');
      window.location.href = `${process.env.REACT_APP_API_SERVER}/auth/logout`;
    }
    if (abcLoginRes?.data?.error) return console.log(`abcLoginResErr2:::${abcLoginRes?.data?.error}`);
    if (!abcLoginRes || abcLoginRes?.data?.data) return console.log('abcLoginRes Fail!!!');
    if (abcLoginRes.data.access_token) {
      const resData = AbcLoginResponse.parse(abcLoginRes.data);
      const abcAuth = {
        accessToken: resData.access_token,
        refreshToken: resData.refresh_token,
        tokenType: resData.token_type,
        expiresIn: resData.expire_in,
      };
      window.localStorage.setItem('abcAuth', JSON.stringify(abcAuth));
      // secureLocalStorage.setItem('abcAuth', JSON.stringify(abcAuth));
    }
    return abcLoginRes;
  },
  getAbcWeb3Provider: async (target?: number) => {
    console.log(`!!!!!!!!!! USE ABC-WEB3-PROVIDER :  ${target} !!!!!!!!!!`);
    window.localStorage.setItem('target', target.toString());

    let provider: any = null;
    const rlt = await getSession();

    if (rlt.data?.providerAuthInfo) {
      // TODO: abc-web3-provider 초기화
      const id_token = rlt.data?.providerAuthInfo?.provider_token;
      const service = rlt.data?.providerAuthInfo?.provider;
      const data = JSON.parse(rlt.data?.providerAuthInfo?.provider_data);
      const email = data.email;
      console.log(service, id_token, data.email);

      const providerOptions = {
        abc: {
          package: AbcWeb3Provider, //required
          options: {
            bappName: 'web3Modal Example App', //required
            chainId: '0x' + (target ?? env.REACT_APP_TARGET_NETWORK).toString(16),
            rpcUrl: RPC_URLS[target ?? env.REACT_APP_TARGET_NETWORK], //required
            email,
            id_token,
            serv: service,
          },
        },
      };
      const web3Modal = new Web3Modal({ providerOptions });

      // Connect Wallet
      const instance = await web3Modal.connect();

      if (instance) {
        const abcUser = JSON.parse(window.localStorage.getItem('abcUser') as string);
        console.log('==========================', abcUser);
        console.log('=============>', abcUser && abcUser?.accounts ? abcUser?.accounts[0].ethAddress : 'No ethAddress');

        provider = new ethers.providers.Web3Provider(instance);
        // await provider.enable();
        const signer = provider.getSigner();
        console.log('signer=============>', signer);
      }
    }
    return provider;
  },
  abcRecover: async (isCheckABC?: License) => {
    console.log('abcRecover:::', isCheckABC === null || isCheckABC === undefined ? 'old user' : 'new user');
    // const { appInfo } = this.props;
    // const isABCApp = window.Android?.activeABCWallet || window.webkit?.messageHandlers?.activeABCWallet;
    // Old app 방어코드
    // if (appInfo?.osType === "IOS" && !isABCApp) return console.log("This is old IOS app...");
    // if (appInfo?.osType === "AOS" && !isABCApp) return console.log("This is old AOS app...");

    let email = '';
    let idToken = '';
    let provider = '';
    const rlt = await getSession();
    if (rlt.data?.providerAuthInfo) {
      const data = JSON.parse(rlt.data?.providerAuthInfo?.provider_data);
      email = data.email;
      idToken = rlt.data?.providerAuthInfo?.provider_token;
      provider = rlt.data?.providerAuthInfo?.provider;
    }
    const abcUser = JSON.parse(window.localStorage.getItem('abcUser'));
    const isNewUser = email && abcUser?.email !== email;

    let abcAuth = JSON.parse(window.localStorage.getItem('abcAuth'));
    if (!abcAuth || isNewUser || isCheckABC) {
      const abcLoginRes = await HelperUtil.abcLogin();
      console.log('abcLoginRes111:::', abcLoginRes);
      if (!abcLoginRes) return;
      // console.log('======>abcLogin@App.js:::', abcLoginRes);
      const flCreate = abcLoginRes?.data?.msg;
      console.log('beforeflCreate && isCheckABC:::', flCreate, isCheckABC);
      if (flCreate === 'Bad Request') return console.log('flCreate returns Bad Request!!!');
      // 신규 가입자 생성
      if (flCreate && isCheckABC) {
        // ABC 로그인
        console.log('!! New ABC user : ', {
          token: idToken,
          provider,
          audience: 'https://mw.myabcwallet.com',
        });
        const result = await abcLogin({
          token: idToken,
          service: provider,
          audience: 'https://mw.myabcwallet.com',
        });
        console.log('for신규result:::', result);
        console.log('!! 신규 가입자 abcLogin = ', result);

        const sixCode = JSON.parse(result.data.msg).sixcode;
        console.log('!! sixcode =', sixCode, email);

        const dto: AbcSnsAddUserDto = {
          username: email,
          code: sixCode,
          joinpath: 'https://colligence.io',
          overage: isCheckABC?.check1 ? 1 : 0,
          agree: isCheckABC?.check2 ? 1 : 0,
          collect: isCheckABC?.check3 ? 1 : 0,
          thirdparty: isCheckABC?.check4 ? 1 : 0,
          advertise: isCheckABC?.check5 ? 1 : 0,
        };

        try {
          const newAccount = await abcJoin(dto);
          console.log('!! created account =', newAccount);
        } catch (e) {
          console.log('!! snsAddUser Error =', e);
        }

        // 신규 가입 후 ABC 로그인
        console.log('!! start to abc sns login !!');
        const abcAuth = await abcController.snsLogin(idToken, provider);
        console.log('!! abc sns login result =', abcAuth);

        // 토큰 저장
        window.localStorage.setItem('abcAuth', JSON.stringify(abcAuth));

        // MPC 지갑 생성
        await accountController.createMpcBaseAccount(
          {
            accountName: email,
            password: '!Colligence001',
            email: email,
          },
          null,
          null
        );

        // 생성된 MPC 지갑 정보 조회
        const { user, wallets } = await accountRestApi.getWalletsAndUserByAbcUid(abcAuth);
        console.log('!! user =', user);

        await accountController.recoverShare({ password: '!owdin001', user, wallets, keepDB: false }, null);

        const abcWallet = user.accounts[0].ethAddress;
        // TODO : update abc wallet address into DB
        await updateAbcAddress(abcWallet);

        const userRes = await getUser();
        console.log('!! Register a new ABC wallet user ... done : ', userRes);
      }
    }

    abcAuth = JSON.parse(window.localStorage.getItem('abcAuth'));
    const EncPV = secureLocalStorage.getItem('EncPV');
    if (!EncPV && abcAuth) {
      // if (!EncPV && abcAuth) {
      const { user, wallets } = await accountRestApi.getWalletsAndUserByAbcUid();
      if (!user) {
        const getSessionRes = await axios
          .get(`/auth/getSession`)
          .catch((error) => console.log(`getSessionErr:::${error.message}`));
        if (getSessionRes) {
          const email = JSON.parse(getSessionRes.data.providerAuthInfo.provider_data)?.email;
          await accountController.createMpcBaseAccount(
            { accountName: email, password: '!owdin001', email: email },
            null
          );
          window.location.reload(); // abc 유저가입은 되어있고 mpc 지갑생성 안된경우
        }
      }
      await accountController.recoverShare({ password: '!owdin001', user, wallets, keepDB: false }, null);

      // TODO : in some cases abc wallet address is missing... why?
      const newAbcUser = JSON.parse(window.localStorage.getItem('abcUser'));
      await updateAbcAddress(newAbcUser.accounts[0].ethAddress);

      await delay(100);
      window.location.reload();
    }
  },
  abcRecoverNew: async () => {
    try {
      const rlt = await getSession();
      if (!rlt.data?.talkenUser?.uid) return;
      let email = '';
      let idToken = '';
      let provider = '';
      if (rlt.data?.providerAuthInfo) {
        const data = JSON.parse(rlt.data?.providerAuthInfo?.provider_data);
        email = data.email;
        idToken = rlt.data?.providerAuthInfo?.provider_token;
        provider = rlt.data?.providerAuthInfo?.provider;
      }

      // ABC 지갑 가입 여부 확인
      const isExist = await abcController.getUser({
        email,
        successIfUserExist: true,
      });
      console.log('!! ABC is exist =', isExist);
      let abcLoginRes = await abcLogin({
        token: idToken,
        service: provider,
        audience: 'https://mw.myabcwallet.com',
      });
      const flCreate = abcLoginRes?.data?.msg;

      const abcAuth = JSON.parse(window.localStorage.getItem('abcAuth'));
      const abcUser = JSON.parse(window.localStorage.getItem('abcUser'));
      const EncPV = secureLocalStorage.getItem('EncPV');

      const isUserChanged = email && abcUser?.email !== email;
      console.log('isUserChanged:::', isUserChanged, abcUser?.email, ' => ', email);

      if (!isExist || flCreate) {
        const sixCode = JSON.parse(abcLoginRes.data.msg).sixcode;
        console.log('!! sixcode =', sixCode, email);

        const dto: AbcSnsAddUserDto = {
          username: email,
          code: sixCode,
          joinpath: 'https://colligence.io',
          overage: 1,
          agree: 1,
          collect: 1,
          thirdparty: 1,
          advertise: 0,
        };

        try {
          const newAccount = await abcJoin(dto);
          console.log('!! created account =', newAccount);
        } catch (e) {
          console.log('!! snsAddUser Error =', e);
        }

        // 신규 가입 후 ABC 로그인
        console.log('!! start to abc sns login !!');
        // const abcAuth = await abcController.snsLogin(idToken, provider);
        abcLoginRes = await abcLogin({
          token: idToken,
          service: provider,
          audience: 'https://mw.myabcwallet.com',
        });
        const resData = AbcLoginResponse.parse(abcLoginRes.data);
        const abcAuth = {
          accessToken: resData.access_token,
          refreshToken: resData.refresh_token,
          tokenType: resData.token_type,
          expiresIn: resData.expire_in,
        };
        window.localStorage.setItem('abcAuth', JSON.stringify(abcAuth)); // 토큰 저장
        console.log('!! new abc sns login result =', abcAuth);

        // MPC 지갑 생성
        await accountController.createMpcBaseAccount(
          {
            accountName: email,
            password: '!Colligence001',
            email: email,
          },
          null,
          null
        );

        // 생성된 MPC 지갑 정보 조회
        const { user, wallets } = await accountRestApi.getWalletsAndUserByAbcUid(abcAuth);
        console.log('!! user =', user);

        await accountController.recoverShare({ password: '!owdin001', user, wallets, keepDB: false }, null);

        const abcWallet = user.accounts[0].ethAddress;
        // TODO : update abc wallet address into DB
        await updateAbcAddress(abcWallet);

        const userRes = await getUser();
        console.log('!! Register a new ABC wallet user ... done : ', userRes);
      } else {
        // 기존 지갑 복구
        if ((!EncPV && abcAuth) || !abcAuth || isUserChanged) {
          // const newAbcAuth = await abcController.snsLogin(idToken, provider);
          const newResData = AbcLoginResponse.parse(abcLoginRes.data);
          const newAbcAuth = {
            accessToken: newResData.access_token,
            refreshToken: newResData.refresh_token,
            tokenType: newResData.token_type,
            expiresIn: newResData.expire_in,
          };
          window.localStorage.setItem('abcAuth', JSON.stringify(newAbcAuth)); // 토큰 저장
          console.log('!! abc sns login result =', newAbcAuth);
          // alert(localStorage.getItem('abcAuth'));

          const { user, wallets } = await accountRestApi.getWalletsAndUserByAbcUid(newAbcAuth);
          // abc 유저가입은 되어있고 mpc 지갑생성 안된경우
          if (!user)
            await accountController.createMpcBaseAccount({ accountName: email, password: '!owdin001', email }, null);
          await accountController.recoverShare({ password: '!owdin001', user, wallets, keepDB: false }, null);

          // TODO : in some cases abc wallet address is missing... why?
          const newAbcUser = JSON.parse(window.localStorage.getItem('abcUser'));
          await updateAbcAddress(newAbcUser.accounts[0].ethAddress);

          await delay(2000);
          // window.location.reload();
        }
      }
    } catch (error) {
      console.log(`abc recover error :::: ${error}`);
    }
  },
  ethAddressValidate: (address: string) => {
    // Ethereum addresses consist of 40 hexadecimal characters.
    const addressRegex = /^(0x)?[0-9a-fA-F]{40}$/;

    // Check if the provided address matches the expected pattern.
    return addressRegex.test(address);
  },
};
export default HelperUtil;
