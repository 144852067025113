import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BigNumber, ethers, utils } from 'ethers';
import { setEthereum, setActivatingConnector, setKlaytn } from '../../redux/slices/wallet';
import {
  injected,
  kaikas,
  abc,
  // walletconnect,
  walletconnectv2,
  // talkenwallet,
} from '../../hooks/connectors';
import { getPrice } from '../../utils/getPrice';
import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import axios from 'axios';
import moment from 'moment';
import klaytn from '../../assets/svg/klaytnFaucet.svg';
import polygon from '../../assets/icon/polygon.svg';
import binance from 'assets/img/icon_binance.png';
import eth from '../../assets/icon/eth.svg';
import solana from '../../assets/svg/Solana.svg';
import mantle from '../../assets/svg/mantle.svg';
import checked from '../../assets/svg/Checked.svg';
import shibadog from '../../assets/svg/ShibaDog.svg';
import talkfaucet from '../../assets/icon/talkKlaytn2.svg';
import roulettePin from '../../assets/roulette/pin-talk3.png';
import roul1k from '../../assets/roulette/01T.png';
import roul10k from '../../assets/roulette/1T.png';
import roul100k from '../../assets/roulette/10T.png';
import roul1000k from '../../assets/roulette/100T.png';
import roul10000k from '../../assets/roulette/1000T.png';
import roul100000k from '../../assets/roulette/10000T.png';
import spinButton from '../../assets/roulette/chance.png';
import spinButtonDisabled from '../../assets/roulette/chance-gray.png';

import { ReactComponent as KlaytnPartner } from '../../assets/svg/klaytn_partner.svg';
import { ReactComponent as Eternal } from '../../assets/svg/eternal_partner.svg';
import { ReactComponent as Bonk } from '../../assets/svg/bonk_partner.svg';
import { ReactComponent as NuriFlexPartner } from '../../assets/svg/nuriflex_partner.svg';
import { ReactComponent as AIRian } from '../../assets/svg/airian_partner.svg';
import { ReactComponent as MetaMCC } from '../../assets/svg/metamcc_partner.svg';
import { ReactComponent as Kommune } from '../../assets/svg/kommune_partner.svg';
import { ReactComponent as ArrowUpRight } from '../../assets/icon/share.svg';
import { ReactComponent as InforCircle } from '../../assets/icon/info_faucet_tooltip.svg';
import { ReactComponent as XBlue } from '../../assets/icon/x_blue.svg';
import { ReactComponent as TalkenTele } from '../../assets/icon/talken_tele_app.svg';
import { ReactComponent as NuriFlex } from '../../assets/svg/nuriflex.svg';

import ReactTooltip from 'react-tooltip';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
// import faucet_01 from 'assets/img/faucet_01.png';
import faucet_01 from 'assets/svg/faucet-apps.svg';
import { Col, Row, Table } from 'react-bootstrap';
import { getSession } from '../../services/services';
import useCopyToClipBoard from '../../hooks/useCopyToClipboard';
import CSnackbar from '../../components/common/CSnackbar';
import { isMobile } from 'react-device-detect';
import { Wheel } from 'react-custom-roulette';
const isProduction = process.env.REACT_APP_PHASE === 'production';
const delay = (time = 1000) => new Promise((resolve) => setTimeout(resolve, time));

const rouletteData = [
  { option: '0', image: { uri: roul1k, sizeMultiplier: 0.6 }, style: { backgroundColor: '#96C6FF' } },
  { option: '1', image: { uri: roul10k, sizeMultiplier: 0.6 }, style: { backgroundColor: '#6DB0FF' } },
  { option: '2', image: { uri: roul100k, sizeMultiplier: 0.6 }, style: { backgroundColor: '#96C6FF' } },
  { option: '3', image: { uri: roul1000k, sizeMultiplier: 0.6 }, style: { backgroundColor: '#6DB0FF' } },
  { option: '4', image: { uri: roul10000k, sizeMultiplier: 0.6 }, style: { backgroundColor: '#96C6FF' } },
  { option: '5', image: { uri: roul100000k, sizeMultiplier: 0.7 }, style: { backgroundColor: '#2F8AF5' } },
];

const Faucet = () => {
  const dispatch = useDispatch();
  const { activate, deactivate, account, library } = useActiveWeb3React();
  const [loginState, setLoginState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { copyToClipBoard, copyResult, setCopyResult } = useCopyToClipBoard();
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    type: '',
    message: '',
  });
  const [listFaucet, setListFaucet] = useState([]);
  const [pointAvailable, setPointAvailable] = useState(0);
  const [session, setSession] = useState(null);
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState<any>();
  const abcUser = JSON.parse(window.localStorage.getItem('abcUser'));
  const ethAddress = abcUser?.accounts[0]?.ethAddress;
  const isSns = window.localStorage.getItem('login') === 'sns';
  const [toAddress, setToAddress] = useState(isSns ? ethAddress : null);
  const [discordUsername, setDiscordUsername] = useState(null);
  const [discordTier, setDiscordTier] = useState(0);
  const [discordInvites, setDiscordInvites] = useState(0);
  const [streakBonus, setStreakBonus] = useState(0);
  const [invitedBonus, setInvitedBonus] = useState(0);
  const [holderRole, setHolderRole] = useState(0);
  const [holderTierName, setHolderTierName] = useState('');
  const [nftListingCount, setNftListingCount] = useState(0);
  const [nftTradesCount, setNftTradesCount] = useState(0);
  const [refererCount, setRefererCount] = useState(0);
  const [isDiscordReady, setIsDiscordReady] = useState(false);
  const [klayClaimed, setKlayClaimed] = useState(false);
  const [isChannelMember, setIsChannelMember] = useState(false);
  const [claimTalkCount, setClaimTalkCount] = useState(0);
  const user = useSelector((state: any) => state.user.user);
  const uid = user.uid;
  const domain = isProduction ? 'https://apps.talken.io' : 'https://dev.apps.talken.io';
  const navigate = useNavigate();
  function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }
  const params = useQuery();
  const err = params.get('err');
  const handleLogin = () => {
    navigate('/accounts?redirectUrl=/faucet');
  };
  const handleConnect = () => {
    window.location.href = `${process.env.REACT_APP_API_SERVER}/auth/discord?redirectUrl=/faucet`;
    // window.location.href = `${process.env.REACT_APP_API_SERVER}/auth/twitter?redirectUrl=/faucet`;
  };
  useEffect(() => {
    setOpenSnackbar({
      open: copyResult,
      type: 'success',
      message: 'Copied!',
    });
  }, [copyResult]);
  useEffect(() => {
    // 상용기에서는 홈으로 이동
    // if (isProduction) return navigate('/');
    if (err === 'discord_idNotMatch') {
      alert(`Another Talken Market account is using this Discord ID.`);
      // navigate('/');
    }
    const fetchGetSession = async () => {
      const rlt = await getSession();
      // console.log('rlt::', rlt);
      if (rlt.data?.dropsUser?.uid) {
        setSession(rlt.data);
        setLoginState(true);
        // setAbleTalk(true);
      }
      const listFaucet = await axios.get(`${process.env.REACT_APP_API_SERVER}/api/reward/getFaucetHistories`);
      if (listFaucet?.data?.status === 1) {
        setListFaucet(listFaucet?.data?.data?.faucetHistories);
        setPointAvailable(listFaucet?.data?.data?.pointAvailable);
      }

      // if (!rlt.data?.discordUser?.provider_username && err === 'discordCallbackErr')
      //   alert(`Your Talken drops id & Discord id dosen't match.`);
    };
    fetchGetSession();
    getUserLevels();
    if (uid) {
      getRewardToday();
    }
  }, []);

  useEffect(() => {
    if (!mustSpin && loginState) {
      getRewardToday();
      getUserLevels();
    }
  }, [mustSpin]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar({
      open: false,
      type: 'info',
      message: '',
    });
  };
  const [selectedWallet, setSelectedWalet] = useState('klaytn');
  const checkSelectWallet = (wallet) => {
    if (selectedWallet == wallet) return true;
    return false;
  };
  const tooltipComingSoon = (
    <Tooltip id="tooltip" className="wrapper-bonk-tooltip">
      <div className="box-bonk-tooltip">Coming Soon</div>
    </Tooltip>
  );

  const getHolderTier = (holderRole: number) => {
    let holderTier = '';
    if (holderRole === 1) holderTier = 'TALK-Plankton';
    else if (holderRole === 2) holderTier = 'TALK-Shrimp';
    else if (holderRole === 3) holderTier = 'TALK-Fish';
    else if (holderRole === 4) holderTier = 'TALK-Whale';
    setHolderTierName(holderTier);
  };

  const toAddressCheck = async () => {
    if (!toAddress) {
      if (isSns) window.location.reload();
      if (!library) {
        await activate(injected, undefined, true);
        dispatch(setActivatingConnector(injected));
        window.localStorage.setItem('login', 'metamask');
      }
      const chainId = await library?.getSigner()?.getChainId();
      const address = await library?.getSigner()?.getAddress();
      const nonce = await library?.getSigner()?.getTransactionCount();
      const message = `Welcome to Talken! Click to sign in and accept the Talken Terms of Service (https://apps.talken.io/terms-conditions) and Privacy Policy (https://apps.talken.io/privacy-policy). This request will not trigger a blockchain transaction or cost any gas fees. Wallet address: ${address} Nonce: ${nonce}`;
      const domain = { chainId }; // All properties on a domain are optional
      const types = { Mail: [{ name: 'contents', type: 'string' }] };
      const mail = { contents: message }; // The data to sign
      const signature = await library
        ?.getSigner()
        ?._signTypedData(domain, types, mail)
        .catch(() => deactivate());
      if (!signature) return; // 서명 거부
      const signingAddress = ethers.utils.verifyTypedData(domain, types, mail, signature)?.toLowerCase();
      setToAddress(signingAddress);
      return signingAddress;
    } else return null;
  };

  const handleSpinClick = async () => {
    // console.log('handleSpinClick:::', toAddress);
    const signingAddress = await toAddressCheck();
    if (!discordUsername) return; // alert('Please connect Discord.');
    if (!signingAddress && !toAddress) return alert('Please click again for connect wallet.');
    if (!mustSpin) {
      const res = await registerFaucetDrops('TALK', signingAddress);
      if (!res) return;
      setMustSpin(true);
    }
  };

  const getUserLevels = async () => {
    // const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/api/reward/getUserLevels`, {
    //   withCredentials: true,
    // });
    const res2 = await axios.get(`${process.env.REACT_APP_API_SERVER}/api/reward/getInvitesByDiscordBot`, {
      withCredentials: true,
    });
    const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/api/reward/getChances`, {
      withCredentials: true,
    });
    if (res.data?.status && !res.data?.data?.errMsg) {
      setIsDiscordReady(true);
      console.log(res.data);
      const tier = res.data?.data?.tier || 0;
      const streakBonus = res.data?.data?.consecutive || 0;
      const holderRole = res.data?.data?.holderRole || 0;
      const nftListingCount = res.data?.data?.nftListingCount || 0;
      const nftTradesCount = res.data?.data?.nftTradesCount || 0;
      const refererCount = res.data?.data?.refererCount || 0;
      const invitedBonus = res.data?.data?.invitedBonus || 0;
      setDiscordTier(tier);
      setStreakBonus(streakBonus);
      setInvitedBonus(invitedBonus);
      setHolderRole(holderRole);
      getHolderTier(holderRole);
      setNftListingCount(nftListingCount);
      setNftTradesCount(nftTradesCount);
      setRefererCount(refererCount);
    }
    if (res2.data?.status) {
      let invites = res2.data?.data?.usesTotal || 0;
      if (invites > 10) invites = 10;
      setDiscordInvites(invites);
      const discordUsername = res2.data?.data?.discordUsername;
      if (discordUsername) setDiscordUsername(discordUsername);
    } else {
      if (res2.data?.message) console.log(`getInvitesByDiscordBot::${res2.data?.message}`);
    }
  };

  const registerFaucetDrops = async (symbol: string, signingAddress: string) => {
    try {
      setIsLoading(true);
      const result = await axios.post(
        `${process.env.REACT_APP_API_SERVER}/api/reward/registerFaucetDrops`,
        {
          symbol,
          toAddress: signingAddress || toAddress,
        },
        { withCredentials: true }
      );
      if (result?.data?.status !== 1) {
        const errMsg = result.data?.message;
        alert(errMsg);
        return false;
      } else {
        window.setTimeout(() => {
          alert(`Congratulations! You've won ${result?.data?.data} TALK.`);
        }, 4200);
        let prizeNum = '0';
        if (result?.data?.data === '0.1' || result?.data?.data === 0.1) prizeNum = '0';
        else if (result?.data?.data === '1' || result?.data?.data === 1) prizeNum = '1';
        else if (result?.data?.data === '10' || result?.data?.data === 10) prizeNum = '2';
        else if (result?.data?.data === '100' || result?.data?.data === 100) prizeNum = '3';
        else if (result?.data?.data === '1000' || result?.data?.data === 1000) prizeNum = '4';
        else if (result?.data?.data === '10000' || result?.data?.data === 10000) prizeNum = '5';
        setPrizeNumber(prizeNum);
        return true;
      }
    } catch (e: any) {
      console.log(e);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const getRewardToday = async () => {
    const rewardKlay: any = await axios
      .get(`${process.env.REACT_APP_API_SERVER}/api/reward/getRewardToday?symbol=KLAY&type=loginKLAY2403&toUid=${uid}`)
      .catch((error) => console.log('getRewardToday::', error.message));
    const rewardTalk: any = await axios
      .get(`${process.env.REACT_APP_API_SERVER}/api/reward/getRewardToday?type=ROULETTE_REWARD&toUid=${uid}`)
      .catch((error) => console.log('getRewardBonkToday::', error.message));
    // console.log('bonkcount', rewardTalk);
    if (rewardKlay.data?.data?.faucetToday) setKlayClaimed(true);
    if (rewardTalk.data?.data?.faucetToday) setClaimTalkCount(rewardTalk.data?.data?.faucetToday);
  };

  const toolTipHolderTier = (
    <ReactTooltip
      id="tooltipHolderTier"
      className="tooltip-box-faucet"
      multiline={true}
      place={`${isMobile ? 'bottom' : 'top'}`}
      effect={'solid'}
    >
      <ul className="mt-0 p-l-10">
        <li>
          Only verified TALK holders with TALK-Shrimp tier or higher, or users who have listed NFTs (OpenSea floor price
          of $10 or more) onTalken Market, are eligible to win either 10 TALK, 100 TALK, 1,000 TALK, or 10,000 TALK.
        </li>
        <li>The 'TALK Holder Tier' grants bonuses up to 4 based on tier.</li>
        <li>For more details on 'TALK Holder Tier' and 'Talken Discord Tier', please refer to Talken Discord.</li>
      </ul>
    </ReactTooltip>
  );
  const toolTipDiscordTier = (
    <ReactTooltip
      id="tooltipDiscordTier"
      className="tooltip-box-faucet"
      multiline={true}
      place={`${isMobile ? 'bottom' : 'top'}`}
      effect={'solid'}
    >
      <ul className="mt-0 p-l-10">
        <li>The 'Talken Discord Tier' offers bonuses up to 5 based on tier.</li>
        <li>For more details on 'Talken Discord Tier', please refer to Talken Discord.</li>
      </ul>
    </ReactTooltip>
  );
  const tooltipMarketInvitees = (
    <ReactTooltip
      id="tooltipMarketInvitees"
      className="tooltip-box-faucet"
      multiline={true}
      place={`${isMobile ? 'bottom' : 'top'}`}
      effect={'solid'}
    >
      <ul className="mt-0 p-l-10">
        <li>
          The '# of Market Invitees' awards 1 bonus each to the inviter and invitee when signing up Talken Market via
          the inviter's code/link and connecting to Discord, up to a maximum of 5 bonuses.
        </li>
      </ul>
    </ReactTooltip>
  );
  const tooltipNFTListings = (
    <ReactTooltip
      id="tooltipNFTListings"
      className="tooltip-box-faucet"
      multiline={true}
      place={`${isMobile ? 'bottom' : 'top'}`}
      effect={'solid'}
    >
      <ul className="mt-0 p-l-10">
        <li>
          Only verified TALK holders with TALK-Shrimp tier or higher, or users who have listed NFTs (OpenSea floor price
          of $10 or more) onTalken Market, are eligible to win either 10 TALK, 100 TALK, 1,000 TALK, or 10,000 TALK.
        </li>
        <li>
          The '# of NFT Listings' provides bonuses up to a maximum of 5 based on the cumulative number of NFTs listed by
          event participants on Talken Market. However, these NFTs must be confirmed with an FP (Floor Price) of $10 or
          more on OpenSea, and only one NFT per collection will be accepted for listing count.
        </li>
      </ul>
    </ReactTooltip>
  );
  const tooltipNFTTrades = (
    <ReactTooltip
      id="tooltipNFTTrades"
      className="tooltip-box-faucet"
      multiline={true}
      place={`${isMobile ? 'bottom' : 'top'}`}
      effect={'solid'}
    >
      <ul className="mt-0 p-l-10">
        <li>
          The '# of NFT Trades' offers bonuses up to a maximum of 5 based on the number of NFTs traded(buy & sell) by
          event participants for USD $10 or more on Talken Market on the given day. However, these NFTs must also be
          viewable on OpenSea.
        </li>
      </ul>
    </ReactTooltip>
  );
  const tooltipStreakBonus = (
    <ReactTooltip
      id="tooltipStreakBonus"
      className="tooltip-box-faucet"
      multiline={true}
      place={`${isMobile ? 'bottom' : 'top'}`}
      effect={'solid'}
    >
      <ul className="mt-0 p-l-10">
        <li>'Streak Bonus' grants up to 5 bonuses for participating consecutively every day.</li>
      </ul>
    </ReactTooltip>
  );
  const tooltipDailyChances = (
    <ReactTooltip
      id="tooltipDailyChances"
      className="tooltip-box-faucet"
      multiline={true}
      place={`${isMobile ? 'bottom' : 'top'}`}
      effect={'solid'}
    >
      <ul className="mt-0 p-l-10">
        <li>The total number of chances is the sum of the number obtained from each quest.</li>
      </ul>
    </ReactTooltip>
  );
  const handleIsOutTeleAppLink = (e) => {
    e.stopPropagation();
    if (isLoading) return;

    setIsLoading(true);

    try {
      alert('Get faucet & join $10k event on Talken Wallet for Telegram.');
    } finally {
      setIsLoading(false);
      window.location.href = 'https://t.me/talkenwallet_bot';
    }
  };
  return (
    <div className="featured-page min-height-content">
      <div className="section-02">
        {/* Faucet */}
        <div className="featured-collections">
          <div className="wrapper-header title-header">
            <div className="header-name pb-3" style={{ paddingTop: '10px' }}>
              Mainnet Faucet
            </div>
          </div>
          <div className="faucet">
            <div className="box-faucet mb-3 p-32 p-xs-24 row position-relative">
              <div className={`ps-0 pe-0 ${isMobile ? 'col-12' : 'col-6'} opacity-7`}>
                <div className="mb-4">Welcome.</div>
                <div className="mb-4">
                  You can receive free gas fee and rewards for each level by performing missions one by one at the
                  Talken rewards faucet.
                </div>
                <div className="mb-4">Get drops right now.</div>
              </div>
              <div className={`ps-0 pe-0 ${isMobile ? 'col-12' : 'col-6'}`}>
                <img src={faucet_01} alt="Faucet" className="w-xs-100" />
              </div>
            </div>
            <div className="box-faucet mb-3 p-32 p-xs-24 row box-grid-faucet">
              <div className="faucet-grid-1 p-xs-0">
                <div className="row p-l-xs-12">
                  <div className="circle circle-custom col-2">
                    <span>1</span>
                  </div>
                  <div className="title-1 col-10 p-t-4">Sign up or log in to Talken Market</div>
                </div>
                <div
                  className={`title-2 ${
                    isMobile ? (loginState ? 'm-b-16' : 'm-b-16') : loginState ? 'm-b-8' : 'm-b-8'
                  } p-l-xs-12`}
                >
                  Easily sign up with your Google, Apple ID or MetaMask.
                </div>
                <div className="wrapper-twitter-faucet">
                  <div className="flex-start gap-8px">
                    <XBlue className="w-xs-22px h-xs-22px" />
                    <a target="_blank" href="https://twitter.com/Talken_" className="follow-x">
                      Follow Talken on X
                    </a>
                  </div>
                  <div className="text-follow-x">For all important information and updates on Talken</div>
                </div>
                <div className="wrapper-twitter-faucet m-t-8 m-t-xs-16">
                  <div className="flex-start gap-8px">
                    <TalkenTele className="w-xs-22px h-xs-22px" />
                    <a target="_blank" href="https://t.me/talkenwallet_bot" className="follow-x">
                      Talken Wallet for Telegram
                    </a>
                  </div>
                  <div className="text-follow-x text-color-tele">💰 $10,000 giveaway live! Join now!</div>
                </div>
              </div>
              <div
                className={`faucet-grid-2 d-flex ps-0 pe-0 ${
                  isMobile ? 'position-static justify-content-center' : 'justify-content-end'
                }`}
              >
                <div className={`${isMobile ? 'd-grid gap-2 grid-col-4' : 'd-flex gap-3'} flex-wrap`}>
                  <div
                    className={`button bg-oval-button border-0 p-b-2 pointer p-l-8 p-r-8 p-t-8 hover-opa-7 align-items-center${
                      checkSelectWallet('eth') ? ' bg-oval-button-klaytn-clicked' : ''
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      // setSelectedWalet('eth');
                    }}
                  >
                    {isMobile ? (
                      <img width={30} height={30} className="m-l-10 m-b-4 m-t-6" src={eth} alt="" />
                    ) : (
                      <img width={40} height={40} className="m-l-10 m-t-6" src={eth} alt="" />
                    )}
                    <div className="label-coin">Ethereum</div>
                  </div>
                  <div
                    className={`button bg-oval-button border-0 p-b-2 pointer p-l-8 p-r-8 p-t-8 hover-opa-7 align-items-center${
                      checkSelectWallet('solana') ? '  bg-oval-button-klaytn-clicked' : ''
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      // setSelectedWalet('solana');
                    }}
                  >
                    {isMobile ? (
                      <img width={30} height={30} className="m-l-10 m-b-4 m-t-6" src={solana} alt="" />
                    ) : (
                      <img width={40} height={40} className="m-l-10 m-t-6" src={solana} alt="" />
                    )}
                    <div className="label-coin">Solana</div>
                  </div>
                  <div
                    className={`button bg-oval-button border-0 p-b-2 pointer p-l-4 p-t-8 hover-opa-7${
                      checkSelectWallet('bnb') ? ' bg-oval-button-klaytn-clicked' : ''
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      // setSelectedWalet('bnb');
                    }}
                  >
                    {isMobile ? (
                      <img width={30} height={30} className="m-l-14 m-b-4 m-t-6" src={binance} alt="" />
                    ) : (
                      <img width={40} height={40} className="m-l-14 m-t-6" src={binance} alt="" />
                    )}
                    <div className="label-coin">BNB Chain</div>
                  </div>
                  <div
                    className={`button bg-oval-button border-0 p-b-2 pointer p-l-4 p-t-8 hover-opa-7${
                      checkSelectWallet('polygon') ? ' bg-oval-button-klaytn-clicked' : ''
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      // setSelectedWalet('polygon');
                    }}
                  >
                    {isMobile ? (
                      <img width={30} height={30} className="m-l-14 m-b-4 m-t-6" src={polygon} alt="" />
                    ) : (
                      <img width={40} height={40} className="m-l-14 m-t-6" src={polygon} alt="" />
                    )}
                    <div className="label-coin">Polygon</div>
                  </div>
                  <div
                    className={`button bg-oval-button border-0 p-b-2 pointer p-l-4 p-t-8 hover-opa-7${
                      checkSelectWallet('klaytn') ? ' bg-oval-button-klaytn-clicked' : ''
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedWalet('klaytn');
                    }}
                  >
                    {isMobile ? (
                      <img width={30} height={30} className="m-l-14 m-b-4 m-t-6" src={klaytn} alt="" />
                    ) : (
                      <img width={40} height={40} className="m-l-14 m-t-6" src={klaytn} alt="" />
                    )}
                    <div className="label-coin">Klaytn</div>
                  </div>
                  <div
                    className={`button bg-oval-button border-0 p-b-2 pointer p-l-4 p-t-8 hover-opa-7${
                      checkSelectWallet('mantle') ? ' bg-oval-button-klaytn-clicked' : ''
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      // setSelectedWalet('mantle');
                    }}
                  >
                    {isMobile ? (
                      <img width={30} height={30} className="m-l-14 m-b-4 m-t-6" src={mantle} alt="" />
                    ) : (
                      <img width={40} height={40} className="m-l-14 m-t-6" src={mantle} alt="" />
                    )}
                    <div className="label-coin">Mantle</div>
                  </div>
                </div>
                {/* <div>
                  <div
                    className={`button bg-oval-button border-0 p-b-2 pointer p-l-4 p-t-8 hover-opa-7`}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {isMobile ? (
                      <img width={30} height={30} className="m-l-14 m-b-4 m-t-6" src={mantle} alt="" />
                    ) : (
                      <img width={40} height={40} className="m-l-14 m-t-6" src={mantle} alt="" />
                    )}
                    <div className="label-coin">Mantle</div>
                  </div>
                </div> */}
              </div>
              {loginState ? (
                <div className={`faucet-grid-3 p-0 ${isMobile ? '' : 'd-flex justify-content-between pe-0'}`}>
                  <div className={`title-4 d-flex ${isMobile ? 'w-100 justify-content-center' : 'col-6 m-l-38'}`}>
                    <div className="d-flex align-items-center">
                      {isMobile ? (
                        <img width={20} height={20} className="" src={checked} alt="" />
                      ) : (
                        <img width={30} height={30} className="" src={checked} alt="" />
                      )}
                    </div>
                    <div className="d-flex align-items-center m-l-10 fs-16">{user.name}</div>
                  </div>

                  <div
                    className={`d-flex w-100 align-items-end ${
                      isMobile ? 'justify-content-center' : 'justify-content-end'
                    }`}
                  >
                    <div
                      className={`button bg-sign-${
                        klayClaimed ? 'gray' : 'blue'
                      }-button justify-content-center border-0 pointer align-items-center bottom-0 ${
                        isMobile ? 'position-static mb-0' : ''
                      }`}
                      // onClick={async (e) => {
                      //   e.stopPropagation();
                      //   try {
                      //     // if (isProduction) return alert('Comming soon!');
                      //     // if (session.providerAuthInfo?.provider === 'WALLET')
                      //     //   return alert('Please login Log In / Sign Up With SNS account.');
                      //     if (!discordUsername) return alert('Please connect Discord first.');
                      //     if (isLoading) return;
                      //     if (klayClaimed) return;
                      //     setIsLoading(true);
                      //     const signingAddress = await toAddressCheck();
                      //     if (!signingAddress && !toAddress) return alert('Please click again for connect wallet.');
                      //     const result = await axios.post(
                      //       `${process.env.REACT_APP_API_SERVER}/api/reward/registerFaucetDrops`,
                      //       { symbol: 'KLAY', toAddress: signingAddress || toAddress },
                      //       { withCredentials: true }
                      //     );
                      //     // console.log('result:::', result);
                      //     if (result.data?.status !== 1) {
                      //       const errMsg = result.data?.message;
                      //       if (errMsg === 'You can try tomorrow.') setKlayClaimed(true);
                      //       alert(errMsg);
                      //     } else {
                      //       alert('Claim success!!');
                      //       setKlayClaimed(true);
                      //     }
                      //   } finally {
                      //     setIsLoading(false);
                      //   }
                      // }}
                      onClick={handleIsOutTeleAppLink}
                    >
                      <div className="label-sign">Claim 0.01 KLAY / Day</div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className={`faucet-grid-3 pe-0 align-items-center ${
                    isMobile ? '' : 'd-flex justify-content-between'
                  }`}
                >
                  <div className={`d-flex w-100 ${isMobile ? 'justify-content-center' : 'm-l-28'}`}>
                    <div
                      className={`col-6 button bg-sign-blue-button border-0 p-b-2 pointer p-l-8 p-r-8 p-t-8 align-items-center`}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleLogin();
                      }}
                    >
                      <div className="label-sign">Sign Up / Log in</div>
                    </div>
                  </div>
                  <div
                    className={`d-flex col-6 title-3 align-item-center ${
                      isMobile ? 'w-100 justify-content-center' : 'justify-content-end'
                    }`}
                  >
                    Free gas fee : 0.01 KLAY / Day
                  </div>
                </div>
              )}
            </div>
            <div className="box-faucet mb-3 p-32 p-xs-24 row">
              <div className="faucet-grid-1">
                <div className="row">
                  <div className="circle circle-custom col-2">
                    <span>2</span>
                  </div>
                  <div className="title-1 m-b-30 col-10 p-t-4">Connect Discord</div>
                </div>
                <div className={`title-2 ${isMobile ? (discordUsername ? 'm-b-10' : 'm-b-10') : ''}`}>
                  Connect to Talken's Discord and feel free to ask questions and communicate about Talken services or
                  NFTs.
                </div>
              </div>
              <div
                className={`faucet-grid-2 d-flex ps-0 pe-0 m-b-10 ${
                  isMobile ? 'position-static justify-content-center' : 'justify-content-end margin-top--50'
                }`}
              >
                <div className={`button bg-oval-button border-0 p-b-2 pointer p-l-4 p-t-8 hover-opa-7`}>
                  {isMobile ? (
                    <img width={30} height={30} className="m-l-14 m-b-4 m-t-6" src={talkfaucet} alt="" />
                  ) : (
                    <img width={40} height={40} className="m-l-14 m-t-6" src={talkfaucet} alt="" />
                  )}
                  <div className="label-coin">TALK</div>
                </div>
                {/* <div className={`${isMobile ? 'd-flex gap-2 m-l-16' : 'd-flex gap-3 m-l-16'} flex-wrap`}>
                  <div className={`bg-square-button border-0 pointer align-items-center`}>
                    {isMobile ? (
                      <img width={44} height={44} className="m-l-8 m-b-4 m-t-6" src={shibadog} alt="" />
                    ) : (
                      <img width={44} height={44} className="m-l-8 m-t-6" src={shibadog} alt="" />
                    )}
                  </div>
                </div> */}
              </div>
              {discordUsername ? (
                <div className={`faucet-grid-3 ${isMobile ? '' : 'd-flex justify-content-between pe-0'}`}>
                  <div className={`title-4 d-flex ${isMobile ? 'w-100 justify-content-center' : 'col-6 m-l-26'}`}>
                    <div className="d-flex align-items-center">
                      {isMobile ? (
                        <img width={20} height={20} className="" src={checked} alt="" />
                      ) : (
                        <img width={30} height={30} className="" src={checked} alt="" />
                      )}
                    </div>
                    <div className="d-flex align-items-center m-l-10 fs-16">{discordUsername}</div>
                  </div>
                  {/* <div className={`d-flex w-100 ${isMobile ? 'justify-content-center' : 'justify-content-end'}`}>
                    <OverlayTrigger placement="top" overlay={tooltipComingSoon}>
                      <div
                        className={`button bg-sign-${
                          isProduction ? 'gray' : 'blue'
                        }-button border-0 pointer align-items-center bottom-0 ${
                          isMobile ? 'position-static mb-0' : ''
                        }`}
                        onClick={async (e) => {
                          e.stopPropagation();
                          if (isProduction) return alert('Comming soon!');
                          if (isLoading) return;
                          setIsLoading(true);
                          const result = await axios.post(
                            `${process.env.REACT_APP_API_SERVER}/api/reward/registerFaucetDrops`,
                            { symbol: 'TALK' },
                            { withCredentials: true }
                          );
                          // console.log('randomResult:::', result);
                          if (result?.data?.status !== 1) {
                            const errMsg = result.data?.message;
                            alert(errMsg);
                            if (errMsg === 'You need to join Talken channel.')
                              window.open(`https://discord.gg/S33c5DA9cW`, '_blank');
                          } else {
                            alert('Claim success!!');
                          }
                          setIsLoading(false);
                        }}
                      >
                        <div className="label-sign">Claim 1 ~ 1000 TALK / Day</div>
                      </div>
                    </OverlayTrigger>
                  </div> */}
                  <div
                    style={{ marginLeft: isMobile ? '' : '-30px' }}
                    className={`d-flex align-item-center col-6 title-3 ${
                      isMobile ? ' w-100 justify-content-center' : 'justify-content-end'
                    }`}
                  >
                    Reward : 0.1 TALK + Randomly up to 10,000 TALK / Chance
                    <br />
                    {`${pointAvailable === 0 ? 0 : getPrice(pointAvailable, '')} TALK remaining in budget`}
                  </div>
                </div>
              ) : (
                <div
                  className={`faucet-grid-3 pe-0 align-items-center ${
                    isMobile ? 'padding-left-unset' : 'd-flex justify-content-between'
                  }`}
                >
                  <div className={`d-flex w-100 ${isMobile ? 'justify-content-center' : 'm-l-28'}`}>
                    <div
                      className={`col-6 button ${'bg-sign-blue-button'} border-0 p-b-2 pointer p-l-8 p-r-8 p-t-8 align-items-center`}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!loginState) handleLogin();
                        // if (session.providerAuthInfo?.provider === 'WALLET')
                        //   return alert('Please login Log In / Sign Up With SNS account.');
                        else handleConnect();
                      }}
                    >
                      <div className="label-sign">Connect Discord</div>
                    </div>
                  </div>
                  <div
                    className={`d-flex align-item-center col-6 title-3 ${
                      isMobile ? ' w-100 justify-content-center' : 'justify-content-end'
                    }`}
                  >
                    Reward : 0.1 TALK + Randomly up to 10,000 TALK / Chance
                    <br />
                    {`${pointAvailable === 0 ? 0 : getPrice(pointAvailable, '')} TALK remaining in budget`}
                  </div>
                </div>
              )}

              {true && (
                <div className="bonk-roulette-div">
                  <div className="bonk-roulette">
                    <div style={{ transform: 'rotate(-48deg) scale(0.75)' }}>
                      <Wheel
                        mustStartSpinning={mustSpin}
                        prizeNumber={prizeNumber}
                        data={rouletteData}
                        outerBorderColor="#FFFFFF"
                        radiusLineWidth={0}
                        pointerProps={{
                          src: roulettePin,
                          style: { transform: 'rotate(48deg) scale(0.8)', top: '30px', right: '20px' },
                        }}
                        onStopSpinning={() => {
                          setMustSpin(false);
                        }}
                        textDistance={75}
                        spinDuration={0.3}
                      />
                    </div>
                    <div className="roulette-button">
                      <img
                        className={'bonk-spin-button'}
                        src={
                          discordUsername &&
                          holderRole +
                            discordTier +
                            invitedBonus +
                            refererCount +
                            nftListingCount +
                            nftTradesCount +
                            streakBonus -
                            claimTalkCount >
                            0
                            ? spinButton
                            : spinButtonDisabled
                        }
                        onClick={async (e) => {
                          e.stopPropagation();
                          if (!isLoading) handleSpinClick();
                        }}
                      />
                      <div
                        className={'bonk-spin-button-count'}
                        onClick={async (e) => {
                          e.stopPropagation();
                          if (!isLoading) handleSpinClick();
                        }}
                      >
                        {isDiscordReady &&
                        holderRole +
                          discordTier +
                          invitedBonus +
                          refererCount +
                          nftListingCount +
                          nftTradesCount +
                          streakBonus -
                          claimTalkCount >
                          0
                          ? holderRole +
                            discordTier +
                            invitedBonus +
                            refererCount +
                            nftListingCount +
                            nftTradesCount +
                            streakBonus -
                            claimTalkCount
                          : 0}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {true && (
                <div className={'bonk-count-container p-l-xs-0 p-r-xs-0'}>
                  {/* <div className={isMobile ? 'mobile-bonk-count-div' : 'bonk-count-div'}>
                    <div className={'bonk-count-box'}>
                      <div className={'bonk-count-text-1'}>Talken Discord Role</div>
                      <div className={'bonk-count-text-2'}>
                        {discordTier !== 0 && (
                          <>
                            <div className={'bonk-count-2'}>{'@Tier'}</div>&nbsp;
                            <div className={'bonk-count-1'}>{discordTier}</div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className={'bonk-count-box'}>
                      <div className={'bonk-count-text-1'}>Talken Discord Invites</div>
                      <div className={'bonk-count-text-2'}>
                        <div className={'bonk-count-1'}>{isDiscordReady ? discordInvites : 0}</div>
                      </div>
                    </div>
                    <div className={'bonk-count-box'}>
                      <div className={'bonk-count-text-1'}>Daily Chances</div>
                      <div className={'bonk-count-text-2'}>
                        <div className={'bonk-count-1'}>
                          {isDiscordReady && discordTier + discordInvites + 1 - claimTalkCount > 0
                            ? discordTier + discordInvites + 1 - claimTalkCount
                            : 0}
                        </div>
                        <div className={'bonk-count-2'}>{` / ${
                          isDiscordReady ? `(1 + ${discordTier} + ${discordInvites})` : 0
                        }`}</div>
                      </div>
                    </div>
                  </div> */}
                  <div className={isMobile ? 'mobile-bonk-count-div' : 'bonk-count-div'}>
                    <div className={'bonk-count-box'}>
                      <div className={'bonk-count-text-1'}>
                        <span className="big-text">TALK Holder Tier</span>
                        {loginState && discordUsername && holderTierName && (
                          <span className="small-text">{`: @${holderTierName}`}</span>
                        )}
                        <InforCircle
                          className="m-l-4 hover-opa-7 outline-unset"
                          data-for="tooltipHolderTier"
                          data-tip
                        />
                        <>{toolTipHolderTier}</>
                      </div>
                      <div className={'bonk-count-text-2'}>
                        +<div className={'bonk-count-1'}>{isDiscordReady ? holderRole : 0}</div>
                      </div>
                    </div>
                    <div className={'bonk-count-box'}>
                      <div className={'bonk-count-text-1'}>
                        <span className="big-text">Talken Discord Tier</span>
                        {loginState && discordUsername && (
                          <span className="small-text">
                            {`: @Tier ${isDiscordReady && discordTier ? discordTier : 0}`}
                          </span>
                        )}
                        <InforCircle
                          className="m-l-4 hover-opa-7 outline-unset"
                          data-for="tooltipDiscordTier"
                          data-tip
                        />
                        <>{toolTipDiscordTier}</>
                      </div>
                      <div className={'bonk-count-text-2'}>
                        +<div className={'bonk-count-1'}>{isDiscordReady ? discordTier : 0}</div>
                      </div>
                    </div>
                    <div className={'bonk-count-box'}>
                      <div className={'bonk-count-text-1'}>
                        <span className="big-text"># of Market Invitees</span>
                        <InforCircle
                          className="m-l-4 hover-opa-7 outline-unset"
                          data-for="tooltipMarketInvitees"
                          data-tip
                        />
                        <>{tooltipMarketInvitees}</>
                      </div>
                      <div className={'bonk-count-text-2'}>
                        +<div className={'bonk-count-1'}>{isDiscordReady ? invitedBonus + refererCount : 0}</div>
                      </div>
                    </div>
                    <div className={'bonk-count-box'}>
                      <div className={'bonk-count-text-1'}>
                        <span className="big-text"># of NFT Listings</span>
                        <InforCircle
                          className="m-l-4 hover-opa-7 outline-unset"
                          data-for="tooltipNFTListings"
                          data-tip
                        />
                        <>{tooltipNFTListings}</>
                      </div>
                      <div className={'bonk-count-text-2'}>
                        +<div className={'bonk-count-1'}>{isDiscordReady ? nftListingCount : 0}</div>
                      </div>
                    </div>
                    <div className={'bonk-count-box'}>
                      <div className={'bonk-count-text-1'}>
                        <span className="big-text"># of NFT Trades</span>
                        <InforCircle className="m-l-4 hover-opa-7 outline-unset" data-for="tooltipNFTTrades" data-tip />
                        <>{tooltipNFTTrades}</>
                      </div>
                      <div className={'bonk-count-text-2'}>
                        +<div className={'bonk-count-1'}>{isDiscordReady ? nftTradesCount : 0}</div>
                      </div>
                    </div>
                    <div className={'bonk-count-box'}>
                      <div className={'bonk-count-text-1'}>
                        <span className="big-text">Streak Bonus</span>
                        <InforCircle
                          className="m-l-4 hover-opa-7 outline-unset"
                          data-for="tooltipStreakBonus"
                          data-tip
                        />
                        <>{tooltipStreakBonus}</>
                      </div>
                      <div className={'bonk-count-text-2'}>
                        +<div className={'bonk-count-1'}>{isDiscordReady ? streakBonus : 0}</div>
                      </div>
                    </div>
                    <div className={'bonk-count-box center-box'}>
                      <div className={'bonk-count-text-1'}>
                        <span className="big-text">Daily Chances</span>
                        <InforCircle
                          className="m-l-4 hover-opa-7 outline-unset"
                          data-for="tooltipDailyChances"
                          data-tip
                        />
                        <>{tooltipDailyChances}</>
                      </div>
                      <div className={'bonk-count-text-2'}>
                        <div className={'bonk-count-1'}>
                          {isDiscordReady &&
                          holderRole +
                            discordTier +
                            invitedBonus +
                            refererCount +
                            nftListingCount +
                            nftTradesCount +
                            streakBonus -
                            claimTalkCount >
                            0
                            ? holderRole +
                              discordTier +
                              invitedBonus +
                              refererCount +
                              nftListingCount +
                              nftTradesCount +
                              streakBonus -
                              claimTalkCount
                            : 0}
                        </div>
                        <div className="bonk-slash">/</div>
                        {/* <div className={'bonk-count-2'}>{`${
                          isDiscordReady
                            ? holderRole +
                              discordTier +
                              invitedBonus +
                              refererCount +
                              nftListingCount +
                              nftTradesCount +
                              streakBonus
                            : 0
                        }`}</div> */}
                        <div className={'bonk-count-2'}>
                          {isDiscordReady &&
                          holderRole +
                            discordTier +
                            invitedBonus +
                            refererCount +
                            nftListingCount +
                            nftTradesCount +
                            streakBonus -
                            claimTalkCount >
                            0
                            ? holderRole +
                              discordTier +
                              invitedBonus +
                              refererCount +
                              nftListingCount +
                              nftTradesCount +
                              streakBonus
                            : claimTalkCount}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {true && (
              <div className="box-faucet mb-3 p-32 row">
                <div className="row">
                  <div className="circle circle-custom col-2">
                    <span>3</span>
                  </div>
                  <div className="title-1 m-b-30 col-10 p-t-4">Invite friends</div>
                </div>
                <div className="title-2 m-b-16 m-b-xs-24">
                  Invite friends now to receive extra roulette bonuses and Talken Market trading rewards.
                  <ul className="m-t-8 m-t-xs-10 p-l-24">
                    <li className="lh-26 lh-xs-18 m-b-xs-10">Base Talken Market fee is 2% (OpenSea 2.5%)</li>
                    <li className="lh-26 lh-xs-18 m-b-xs-10">
                      For inviters, 0.5% of the sales amount will be converted to TALK and credited as reward points
                      (ongoing)
                    </li>
                    <li className="lh-26 lh-xs-18 m-b-xs-10">
                      For buyers, 1.0% of the purchase amount will be converted to TALK and credited as reward points
                      (0.5% ongoing + 0.5% event)
                    </li>
                    <li className="lh-26 lh-xs-18">
                      For sellers, 1.0% of the seller's fee will be converted to TALK and credited as reward points
                      (0.5% ongoing + 0.5% event)
                    </li>
                  </ul>
                </div>

                <div className={`${isMobile ? 'd-flex' : 'row d-flex'}`}>
                  <button
                    className={`col-6 button border-0 pointer align-items-center ${isMobile ? '' : 'm-l-40'} ${
                      loginState ? 'bg-copy-invite-button' : 'bg-copy-invite-not-login'
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      // if (isProduction) return alert('Comming soon!');
                      if (loginState) return copyToClipBoard(`${domain}/accounts?ref=${user?.uid}`);
                    }}
                  >
                    <div className="label-sign">Copy invite link</div>
                  </button>
                  <button
                    className={`col-6 button border-0 m-l-10 p-b-2 pointer align-items-center ${
                      loginState ? 'bg-copy-invite-button' : 'bg-copy-invite-not-login'
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      // if (isProduction) return alert('Comming soon!');
                      if (loginState) return copyToClipBoard(user?.uid);
                    }}
                  >
                    <div className="label-sign">Copy invite code</div>
                  </button>
                </div>
              </div>
            )}
            <div className="box-faucet d-block mb-3 p-32 p-xs-24">
              <Row className="m-l-0 mb-4 m-b-xs-16 fs-24 fs-xs-16 title-1">Faucet drops</Row>
              <div className="horizontal-scroll">
                <Table className="fs-xs-12">
                  <thead>
                    <tr>
                      <th>Mainnet</th>
                      <th>Age</th>
                      <th>Value</th>
                      <th>To</th>
                      <th>Type</th>
                    </tr>
                  </thead>
                  {listFaucet.length > 0 && (
                    <tbody>
                      {listFaucet.map((item, i) => (
                        <tr key={i}>
                          <td className="fw-normal">
                            Klaytn
                            {/* {!isMobile && item.platform === 'KLAYTN'
                              ? 'Klaytn Cypress'
                              : `${item.platform.charAt(0)}${item.platform?.slice(1)?.toLowerCase()}`} */}
                          </td>
                          <td>{moment(item.updatedAt).fromNow()}</td>
                          <td>
                            {`${item.point ? getPrice(item.point, '') : 0} TALK`}
                            {/* {item.amount} {item.symbol} */}
                          </td>
                          <td className="fw-normal">
                            {item.userName}
                            {/* {isMobile
                              ? `${item.toAddress?.slice(0, 12)}...`
                              : `${item.toAddress?.slice(0, 18)}...${item.toAddress?.slice(-18)}`} */}
                          </td>
                          <td className="fw-normal">
                            {item.type === 'ROULETTE_REWARD' ? 'Roulette Reward' : item.type}
                            {/* <div
                              className="d-flex align-items-center gap-10px hover-cusor-pointer hover-opa-7"
                              onClick={(e) => {
                                e.stopPropagation();
                                window.open(
                                  `https://${isProduction ? 'www' : 'baobab'}.klaytnfinder.io/tx/${item.tx}`,
                                  '_blank'
                                );
                              }}
                            >
                              <div className="opacity-7 fw-normal w-54 w-xs-75">{`${item.tx?.slice(0, 12)}...`}</div>
                              <ArrowUpRight className="path-black dark:path-white" />
                            </div> */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </Table>
                {listFaucet.length <= 0 && (
                  <div className="fs-14 fs-xs-12 opacity-7 text-base text-center p-t-40 p-b-56 p-t-xs-20 p-b-xs-32">
                    “There is no history of Faucet drops.”
                  </div>
                )}
              </div>
            </div>
            <div className="box-faucet d-block mb-3 p-b-8 p-l-32 p-r-18 p-t-14">
              <Row className="m-l-0 mb-3 p-t-10 fs-24 fs-xs-16 title-1">Faucet Partners</Row>
              <Row className="d-grid gap-3 grid-col-6 grid-col-xs-2 p-l-10 p-r-10 w-100">
                <Col className="align-items-center border d-flex h-xs-50px justify-content-center max-h-67px mb-3 m-b-xs-0 rounded-3 w-100 boder-color-faucet">
                  <Bonk className="h-30px h-xs-24px" />
                </Col>
                <Col className="align-items-center border d-flex h-xs-50px justify-content-center max-h-67px mb-3 m-b-xs-0 rounded-3 w-100 boder-color-faucet">
                  <Eternal className="h-27px h-xs-20px w-133px w-xs-110px" />
                </Col>
                <Col className="align-items-center border d-flex h-xs-50px justify-content-center max-h-67px mb-3 m-b-xs-0 p-30 p-xs-14 rounded-3 w-100 boder-color-faucet">
                  <NuriFlex className="h-23px h-xs-16px w-133px w-xs-100px" />
                </Col>
                <Col className="align-items-center border d-flex h-xs-50px justify-content-center max-h-67px mb-3 m-b-xs-0 p-30 p-xs-14 rounded-3 w-100 boder-color-faucet">
                  <AIRian className="h-21px h-xs-17px w-93px w-xs-70px" />
                </Col>
                <Col className="align-items-center border d-flex h-xs-50px justify-content-center max-h-67px mb-3 p-30 p-xs-14 rounded-3 w-100 boder-color-faucet">
                  <MetaMCC className="h-23px h-xs-18px w-126px w-xs-100px" />
                </Col>
                <Col className="align-items-center border d-flex h-xs-50px justify-content-center max-h-67px mb-3 p-30 p-xs-14 rounded-3 w-100 boder-color-faucet">
                  <Kommune className="h-23px h-xs-18px w-126px w-xs-100px" />
                </Col>
              </Row>
            </div>
            <div className="box-faucet d-block mb-3 p-b-32 p-l-32 p-r-32 p-t-22 p-b-xs-24 p-l-xs-24 p-r-xs-24">
              <Row className="m-l-0 mb-2 p-t-10 fs-24 fs-xs-16 title-1">※ Event Guidelines</Row>
              <div className="title-2 ms-0">
                <ul className="mt-0 p-l-24">
                  <li className="lh-26 lh-xs-21 m-b-xs-16">Daily participation and claims reset at 09:00 (UTC+9).</li>
                  <li className="lh-26 lh-xs-21 m-b-xs-16">
                    <span>
                      TALK acquired through events is accumulated as reward points, and can be checked in the{' '}
                    </span>
                    <Link
                      to={loginState ? '/rewards' : '/accounts?redirectUrl=/rewards'}
                      className="color-2f8af5 text-decoration-underline hover-opa-7"
                    >
                      Rewards
                    </Link>
                    <span> menu.</span>
                  </li>
                  <li className="lh-26 lh-xs-21 m-b-xs-16">
                    Reward points can be claimed when holding 10 or more, at which time Klaytn-based Wormhole Bridged
                    TALK will be sent.
                  </li>
                  <li className="lh-26 lh-xs-21 color-white m-b-xs-16">
                    (Warning!) Do not transfer Wormhole Bridged TALK directly to the exchange.
                  </li>
                  <li className="lh-26 lh-xs-21 m-b-xs-16">
                    <span>Wormhole Bridged TALK can be converted to ERC20 TALK at </span>
                    <Link to="/bridge" className="color-2f8af5 text-decoration-underline hover-opa-7">
                      Talken Bridge
                    </Link>
                  </li>
                  <li className="lh-26 lh-xs-21 m-b-xs-16">
                    The 'TALK Holder Tier' grants bonuses up to 4 based on tier.
                  </li>
                  <li className="lh-26 lh-xs-21 m-b-xs-16">
                    The 'Talken Discord Tier' offers bonuses up to 5 based on tier.
                  </li>
                  <li className="lh-26 lh-xs-21 m-b-xs-16">
                    Only verified TALK holders with TALK-Shrimp tier or higher, or users who have listed NFTs (OpenSea
                    floor price of $10 or more) onTalken Market, are eligible to win either 10 TALK, 100 TALK, 1,000
                    TALK, or 10,000 TALK.
                  </li>
                  <li className="lh-26 lh-xs-21 m-b-xs-16">
                    <span>For more details on 'Talken Discord Tier', please refer to </span>
                    <a
                      href="https://discord.gg/S33c5DA9cW"
                      target={'_blank'}
                      rel="noreferrer"
                      className="color-2f8af5 text-decoration-underline hover-opa-7"
                    >
                      Talken Discord
                    </a>
                  </li>
                  <li className="lh-26 lh-xs-21 m-b-xs-16">
                    The '# of Market Invitees' awards 1 bonus each to the inviter and invitee when signing up Talken
                    Market via the inviter's code/link and connecting to Discord, up to a maximum of 5 bonuses.
                  </li>
                  <li className="lh-26 lh-xs-21 m-b-xs-16">
                    The '# of NFT Listings' provides bonuses up to a maximum of 5 based on the cumulative number of NFTs
                    listed by event participants on Talken Market. However, these NFTs must be confirmed with an FP
                    (Floor Price) of $10 or more on OpenSea, and only one NFT per collection will be accepted for
                    listing count.
                  </li>
                  <li className="lh-26 lh-xs-21 m-b-xs-16">
                    The '# of NFT Trades' offers bonuses up to a maximum of 5 based on the number of NFTs traded(buy &
                    sell) by event participants for USD $10 or more on Talken Market on the given day. However, these
                    NFTs must also be viewable on OpenSea.
                  </li>
                  <li className="lh-26 lh-xs-21 m-b-xs-16">
                    'Streak Bonus' grants up to 5 bonuses for participating consecutively every day.
                  </li>
                  <li className="lh-26 lh-xs-21 m-b-xs-16">
                    For a new user's first participation in the roulette event, 10 TALK reward points are added to the
                    remaining budget.
                  </li>
                  <li className="lh-26 lh-xs-21 m-b-xs-16">Event may end early if reward budget is exhausted.</li>
                  <li className="lh-26 lh-xs-21">
                    Unauthorized methods participation may result in account restrictions.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CSnackbar
        open={openSnackbar.open}
        type={openSnackbar.type}
        message={openSnackbar.message}
        handleClose={handleCloseSnackbar}
      />
    </div>
  );
};

export default Faucet;
