import { mockLeaderBoard } from 'pages/homepage/mockData';
import { useEffect, useState } from 'react';
import { customAxios, apiAuthAxios } from 'services/customAxios';
import moment from 'moment';
import makeBlockie from 'ethereum-blockies-base64';
import { getPriceRecentlyListedItems } from 'utils/getPriceRecentlyListedItems';
import useScreenSize from 'components/common/useScreenSize';
import { ReactComponent as IconMoreBoard } from '../../assets/icon/double_down_arrow.svg';

type LeaderBoard = {
  seeMore: boolean;
};
const LeaderBoard: React.FC<LeaderBoard> = ({ seeMore }) => {
  const [tabIsSelected, setTabIsSelected] = useState('tab03');
  const [leaderBoard, setLeaderBoard] = useState([]);
  const [seeMoreMobile, setSeeMoreMobile] = useState(false);
  const today = moment().format('YYYY-MM-DD');
  const dayAgo = moment().subtract(1, 'days').format('YYYY-MM-DD');
  const weekAgo = moment().subtract(1, 'weeks').format('YYYY-MM-DD');
  const monthAgo = moment().subtract(1, 'months').format('YYYY-MM-DD');
  const [startDate, setStartDate] = useState(monthAgo);
  const handleTabSelect = (tab) => {
    if (tab === 'tab01') setStartDate(dayAgo);
    else if (tab === 'tab02') setStartDate(weekAgo);
    else if (tab === 'tab03') setStartDate(monthAgo);
    setTabIsSelected(tab);
  };
  const getLeaderBoard = async () => {
    try {
      const response = await customAxios.get(
        `/api/reward/getRank?startDate=${startDate}&endDate=${today}&limit=${
          screenSize > 768 ? (seeMore ? 10 : 5) : seeMoreMobile ? 10 : 5
        }`
      );
      if (response.data?.data?.trades) setLeaderBoard(response.data?.data?.trades);
    } catch (error: any) {
      console.log(new Error(error));
    }
  };
  useEffect(() => {
    getLeaderBoard();
  }, [tabIsSelected, seeMore, seeMoreMobile]);
  const screenSize = useScreenSize();

  return (
    <div className="wrapper-top-board">
      <div className="d-flex flex-column justify-content-start gap-32px gap-xs-16px w-100">
        <div className="flex-between">
          <div className="fw-9 fs-28 fs-xs-18 lh-33 lh-xs-21">Leaderboard</div>
          <div className="tabs-top-board">
            <div className="item-tab-board">
              <div
                className={`tab ${tabIsSelected === 'tab01' && 'tab-selected'} `}
                onClick={() => handleTabSelect('tab01')}
              >
                1D
              </div>
            </div>
            <div className="item-tab-board">
              <div
                className={`tab ${tabIsSelected === 'tab02' && 'tab-selected'} `}
                onClick={() => handleTabSelect('tab02')}
              >
                7D
              </div>
            </div>
            <div className="item-tab-board">
              <div
                className={`tab ${tabIsSelected === 'tab03' && 'tab-selected'} `}
                onClick={() => handleTabSelect('tab03')}
              >
                1M
              </div>
            </div>
          </div>
        </div>

        <table className="top-board">
          <thead>
            <tr className="fs-14 fs-xs-10 fw-7 color-8787d6">
              <th className="text-center w-56px w-xs-42px">Rank</th>
              <th className="text-xs-center w-xs-108px">User</th>
              <th>Trading Count</th>
              <th className="w-120px w-xs-100px">Trading Rewards</th>
            </tr>
          </thead>
          <tbody className="cjk-font">
            {leaderBoard.map((item, index) => (
              <tr key={index}>
                <td className="text-center">{index + 1}</td>
                <td>
                  <div className="d-flex gap-6px align-items-center gap-xs-4px">
                    <div className="w-26px w-xs-20px h-26px h-xs-20px min-w-26px min-w-xs-20px">
                      <img
                        src={
                          item.userProfileImage ||
                          (item.userAddress ? makeBlockie(item.userAddress.toLowerCase()) : null)
                        }
                        alt="avatar"
                        className="w-100 rounded-circle h-100 object-fit-cover"
                      />
                    </div>
                    <div className="line-clamp-1 fw-5">{item.userName}</div>
                  </div>
                </td>
                <td>{getPriceRecentlyListedItems(item.tradesCount)}</td>
                <td>{getPriceRecentlyListedItems(item.pointSum)} TALK</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {screenSize < 769 &&
        (seeMoreMobile ? (
          <div className="wrapper-hide-mb" onClick={() => setSeeMoreMobile(false)}>
            <IconMoreBoard className="icon-hide-board" width={10} height={10} />
            <span>Hide</span>
          </div>
        ) : (
          <div className="wrapper-more-mb" onClick={() => setSeeMoreMobile(true)}>
            <div className="flex-center gap-8px">
              <IconMoreBoard width={10} height={10} />
              <span>More</span>
            </div>
          </div>
        ))}
    </div>
  );
};
export default LeaderBoard;
